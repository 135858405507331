<template>
  <v-card>
    <v-card-text class="no-print">
      <h3 style="margin-bottom:.5em">Submission Filters</h3>
      <v-row>
        <v-col cols="12" md="3">
          <v-select v-model="statusFilter" :items="statusOptions" label="Status Filter" multiple outlined hide-details></v-select>
        </v-col>
        <v-col cols="12" md="3">
          <date-picker v-model="dateFilterStart" :max-date="dateFilterEnd" :clear-button="true" :hide-details="true" label="Date Range Start"></date-picker>
        </v-col>
        <v-col cols="12" md="3">
          <date-picker v-model="dateFilterEnd" :min-date="dateFilterStart" :clear-button="true" :hide-details="true" label="Date Range End"></date-picker>
        </v-col>
        <v-col cols="12" md="3">
          <v-select v-model="revisionId" :items="revisions" label="Form Version" hide-details outlined>
            <template v-slot:append-outer>
              <v-tooltip top>
                <template v-slot:activator="{ on: tooltipOn }">
                  <v-menu v-if="fieldFiltersAvailable.length > 0">
                    <template v-slot:activator="{ on: menuOn }">
                      <v-btn v-on="{ ...tooltipOn, ...menuOn }" icon style="margin-top:-5px">
                        <v-icon>fal fa-filter</v-icon>
                      </v-btn>
                    </template>
                    <v-list dense>
                      <v-subheader>Add filter on form fields:</v-subheader>
                      <v-list-item v-for="(label, index) in fieldFiltersAvailable" :key="'field-' + index" @click="addFilter(label)">
                        <v-list-item-title>{{ label }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>
                <span>Filter the submissions by data submitted in the form for selected fields</span>
              </v-tooltip>
            </template>
          </v-select>
        </v-col>
        <v-col v-for="({ field, value }, index) of fieldFilters" :key="'field-' + field" cols="6" md="4" lg="3">
          <v-text-field v-model="fieldFilters[index].value" :label="field" outlined hide-details @keyup="updateFilter(index, value)">
            <template v-slot:append>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" icon @click="removeFilter(index)" style="margin-top:-5px">
                    <v-icon color="error">fal fa-times-circle</v-icon>
                  </v-btn>
                </template>
                <span>Remove this filter field</span>
              </v-tooltip>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12">
          <v-select v-model="includeFields" :items="formFields" label="Form fields to include in table" multiple chips small-chips deletable-chips outlined hide-details>
            <template v-slot:append-outer>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" icon color="success" style="top:-7px" @click="exportSubmissions">
                    <v-icon>fal fa-file-excel</v-icon>
                  </v-btn>
                </template>
                <span>Export the filtered submissions to Excel</span>
              </v-tooltip>
            </template>
          </v-select>
        </v-col>
      </v-row>
    </v-card-text>
    <v-data-table :headers="tableHeaders" :items="submissions" :server-items-length="submissionCount" :options.sync="tableOptions" :footer-props="{ 'items-per-page-options': [5, 10, 15, 20, 30, 40, 50] }" @dblclick:row="viewSubmissionDblClick">
      <template v-slot:no-data>
        <v-alert type="info">There are no submissions matching your given search criteria.</v-alert>
      </template>
      <template v-slot:item.submittedDate="{ item }">{{ stringFormatDate(item.submittedDate) }}</template>
      <template v-slot:item.actions="{ item, index }">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon @click="viewSubmission(index, item._id)">
              <v-icon>fal fa-eye</v-icon>
            </v-btn>
          </template>
          <span>View this submission</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import { ref, computed, watch, onMounted } from '@vue/composition-api'
import { stringFormatDate } from '../../../helpers/formatters'
import { saveAs } from 'file-saver'

export default {
  components: {
    DatePicker: () => import('@/components/forms/inputs/DatePicker')
  },
  props: {
    formId: {
      type: String,
      required: true
    }
  },
  setup (props, { root }) {
    const tableOptions = computed({
      get: () => root.$store.state.forms.admin.submission.pagination,
      set: (pagination) => root.$store.commit('forms/setAdminSubmissionPagination', pagination)
    })
    const statusFilter = computed({
      get: () => root.$store.state.forms.admin.submission.filters.status,
      set: (status) => root.$store.commit('forms/updateAdminSubmissionFilters', { status })
    })
    const statusOptions = ref([ 'Pending', 'Submitted', 'Processed' ])
    const dateFilterStart = computed({
      get: () => root.$store.state.forms.admin.submission.filters.dateStart,
      set: (dateStart) => root.$store.commit('forms/updateAdminSubmissionFilters', { dateStart })
    })
    const dateFilterEnd = computed({
      get: () => root.$store.state.forms.admin.submission.filters.dateEnd,
      set: (dateEnd) => root.$store.commit('forms/updateAdminSubmissionFilters', { dateEnd })
    })
    const revisions = ref([])
    const revisionId = computed({
      get: () => root.$store.state.forms.admin.submission.filters.revision,
      set: (revision) => root.$store.commit('forms/updateAdminSubmissionFilters', { revision })
    })
    const formFields = ref([])
    const fieldFilters = computed({
      get: () => root.$store.state.forms.admin.submission.filters.fields,
      set: (fields) => {
        root.$store.commit('forms/updateAdminSubmissionFilters', { fields })
        loadSubmissions()
      }
    })
    const fieldFiltersAvailable = computed(() => {
      let arr = []
      let userIncluded = false
      for (let j = 0; j < fieldFilters.value.length; j++) {
        if (fieldFilters.value[j].field === 'User Name') {
          userIncluded = true
          break
        }
      }
      if (!userIncluded) arr.push('User Name')
      for (let i = 0; i < formFields.value.length; i++) {
        if (formFields.value[i].input === 'repeatable-block' || formFields.value[i].input === 'html-block') continue
        let included = false
        for (let j = 0; j < fieldFilters.value.length; j++) {
          if (fieldFilters.value[j].field === formFields.value[i].value) {
            included = true
            break
          }
        }
        if (!included) arr.push(formFields.value[i].value)
      }
      return arr
    })

    function addFilter (label) {
      fieldFilters.value.push({ field: label, value: '' })
    }

    function updateFilter (index, value) {
      root.$store.commit('forms/updateAdminSubmissionFilterFields', { index, value })
      loadSubmissions()
    }

    function removeFilter (index) {
      fieldFilters.value.splice(index, 1)
    }

    const includeFields = computed({
      get: () => root.$store.state.forms.admin.submission.includeFields,
      set: (val) => {
        root.$store.commit('forms/setAdminSubmissionIncludedFields', val)
        root.$feathers.service('forms/base').patch(props.formId, { submissionViewFields: val })
        loadSubmissions()
      }
    })
    const headers = ref([
      { text: 'Submitted Date', value: 'submittedDate' },
      { text: 'Status', value: 'status' }
    ])
    const tableHeaders = computed(() => {
      let arr = Object.assign([], headers.value)
      for (let i = 0; i < includeFields.value.length; i++) {
        arr.push({ text: includeFields.value[i], value: includeFields.value[i] })
      }
      arr.push({ text: 'Actions', value: 'actions' })
      return arr
    })
    const submissions = ref([])
    const submissionCount = computed(() => root.$store.state.forms.admin.submission.count)
    const formName = ref('')

    watch(() => props.formId, () => {
      if (props.formId != null) {
        root.$feathers.service('forms/base').get(props.formId).then(({ name, access, revisions, statusOptions: formStatusOptions, submissionViewFields }) => {
          formName.value = name
          if (formStatusOptions.length === 0) {
            root.$feathers.service('forms/base').patch(props.formId, { statusOptions: statusOptions.value })
          } else {
            statusOptions.value = formStatusOptions
          }
          if (access.loginRequired && !access.anonymous) {
            headers.value.push({ text: 'User', value: 'user.name' })
          }
          if (submissionViewFields && Array.isArray(submissionViewFields) && submissionViewFields.length > 0) {
            includeFields.value = submissionViewFields
          }
          root.$feathers.service('forms/revision').get(revisions.active).then(({ sections }) => {
            formFields.value = []
            sections.forEach(({ inputs }) => {
              inputs.forEach(({ label, input, options }) => {
                formFields.value.push({ value: label, text: label, input })
              })
            })
          })
        })
        let query = {
          form: props.formId,
          $select: ['version', 'submissions', '_id'],
          $limit: 50
        }
        root.$feathers.service('forms/revision').find({ query }).then(({ data }) => {
          revisions.value = [{ text: 'Any Form Version', value: '' }]
          data.forEach(({ version, submissions, _id }) => {
            revisions.value.push({ text: 'Version ' + version + ' (' + submissions + ' submission' + (submissions !== 1 ? 's' : '') + ')', value: _id })
          })
        })
        loadSubmissions()
      }
    })

    function loadSubmissions () {
      root.$store.dispatch('forms/adminLoadSubmissions', { formId: props.formId }).then((data) => {
        submissions.value = []
        for (let i = 0; i < data.length; i++) {
          let temp = data[i]
          if (includeFields.value.length > 0) {
            for (const { field, value } of data[i].dataArr) {
              for (let j = 0; j < includeFields.value.length; j++) {
                if (includeFields.value[j] === field) {
                  if (Array.isArray(value)) {
                    if (Array.isArray(value[0])) {
                      // Repeatable block
                      temp[field] = value.length + ' Entr' + (value.length === 1 ? 'y' : 'ies')
                    } else {
                      temp[field] = value.join('; ')
                    }
                  } else {
                    temp[field] = value
                  }
                }
              }
            }
          }
          submissions.value.push(temp)
        }
      })
    }
    watch([tableOptions, statusFilter, dateFilterStart, dateFilterEnd, revisionId], () => loadSubmissions())

    function viewSubmission (index, id) {
      root.$store.commit('forms/setAdminSubmissionIndex', index)
      root.$router.push('/forms/admin/submission/' + id)
    }

    function viewSubmissionDblClick (e, { item }) {
      const { _id } = item
      let index = 0
      for (let i = 0; i < submissions.value.length; i++) {
        if (submissions.value[i]._id === _id) {
          index = i
          break
        }
      }
      viewSubmission(index, _id)
    }

    async function exportSubmissions () {
      let raw = await root.$store.dispatch('forms/adminLoadAllSubmissions', { formId: props.formId })
      let rows = []
      let fields = {
        'Name': 0,
        'Status': 1,
        'Submitted Date': 2
      }
      let header = [
        { header: 'Name', align: 'left', key: 'name' },
        { header: 'Status', align: 'left', key: 'status' },
        { header: 'Submitted Date', align: 'right', key: 'submittedDate' }
      ]
      for (const { name, status, submittedDate, data, dataArr } of raw) {
        let temp = { name, status, submittedDate }
        if (data != null) {
          for (let field in data) {
            if (!(field in fields)) {
              fields[field] = header.length
              header.push({ header: field, align: 'left', key: field })
            }
            // let fieldName = 'field_' + fields[field]
            temp[field] = data[field]
          }
        } else if (dataArr != null) {
          for (const { field, value } of dataArr) {
            if (Array.isArray(value) && Array.isArray(value[0])) {
              // This is a repeatable block; we are going to have all of the fields within be exported into their own columns, with the first one being just the field name and the others including a number starting with 2
              for (let i = 0; i < value.length; i++) {
                for (const { field: subField, value: subValue } of value[i]) {
                  let fieldName = field + ' ' + subField + ' ' + (i + 1)
                  let key = field + '-' + subField + '-' + i
                  if (!(fieldName in fields)) {
                    fields[fieldName] = header.length
                    header.push({ header: fieldName, align: 'left', key })
                  }
                  temp[key] = Array.isArray(subValue) ? subValue.join('; ') : subValue
                }
              }
            } else {
              if (!(field in fields)) {
                fields[field] = header.length
                header.push({ header: field, align: 'left', key: field })
              }
              // let fieldName = 'field_' + fields[field]
              temp[field] = Array.isArray(value) ? value.join('; ') : value
            }
          }
        } else {
          continue
        }
        rows.push(temp)
      }
      let filename = formName.value + ' Submissions.xlsx'
      root.$feathers.service('export/xlsx').create({ filename, header, rows }).then((data) => {
        saveAs(new Blob([data.buffer]), filename)
      })
    }

    onMounted(() => {
      if (root.$store.state.forms.admin.submission.filters.form !== props.formId) {
        root.$store.commit('forms/updateAdminSubmissionFilters', { revision: '', fields: [], form: props.formId })
      }
    })

    return {
      tableOptions,
      statusFilter,
      statusOptions,
      revisions,
      revisionId,
      dateFilterStart,
      dateFilterEnd,
      formFields,
      fieldFilters,
      fieldFiltersAvailable,
      addFilter,
      updateFilter,
      removeFilter,
      includeFields,
      headers,
      tableHeaders,
      submissions,
      submissionCount,
      loadSubmissions,
      viewSubmission,
      viewSubmissionDblClick,
      exportSubmissions,
      stringFormatDate
    }
  }
}
</script>
