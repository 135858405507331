var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-text',{staticClass:"no-print"},[_c('h3',{staticStyle:{"margin-bottom":".5em"}},[_vm._v("Submission Filters")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-select',{attrs:{"items":_vm.statusOptions,"label":"Status Filter","multiple":"","outlined":"","hide-details":""},model:{value:(_vm.statusFilter),callback:function ($$v) {_vm.statusFilter=$$v},expression:"statusFilter"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('date-picker',{attrs:{"max-date":_vm.dateFilterEnd,"clear-button":true,"hide-details":true,"label":"Date Range Start"},model:{value:(_vm.dateFilterStart),callback:function ($$v) {_vm.dateFilterStart=$$v},expression:"dateFilterStart"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('date-picker',{attrs:{"min-date":_vm.dateFilterStart,"clear-button":true,"hide-details":true,"label":"Date Range End"},model:{value:(_vm.dateFilterEnd),callback:function ($$v) {_vm.dateFilterEnd=$$v},expression:"dateFilterEnd"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-select',{attrs:{"items":_vm.revisions,"label":"Form Version","hide-details":"","outlined":""},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltipOn = ref.on;
return [(_vm.fieldFiltersAvailable.length > 0)?_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menuOn = ref.on;
return [_c('v-btn',_vm._g({staticStyle:{"margin-top":"-5px"},attrs:{"icon":""}},Object.assign({}, tooltipOn, menuOn)),[_c('v-icon',[_vm._v("fal fa-filter")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-subheader',[_vm._v("Add filter on form fields:")]),_vm._l((_vm.fieldFiltersAvailable),function(label,index){return _c('v-list-item',{key:'field-' + index,on:{"click":function($event){return _vm.addFilter(label)}}},[_c('v-list-item-title',[_vm._v(_vm._s(label))])],1)})],2)],1):_vm._e()]}}])},[_c('span',[_vm._v("Filter the submissions by data submitted in the form for selected fields")])])]},proxy:true}]),model:{value:(_vm.revisionId),callback:function ($$v) {_vm.revisionId=$$v},expression:"revisionId"}})],1),_vm._l((_vm.fieldFilters),function(ref,index){
var field = ref.field;
var value = ref.value;
return _c('v-col',{key:'field-' + field,attrs:{"cols":"6","md":"4","lg":"3"}},[_c('v-text-field',{attrs:{"label":field,"outlined":"","hide-details":""},on:{"keyup":function($event){return _vm.updateFilter(index, value)}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticStyle:{"margin-top":"-5px"},attrs:{"icon":""},on:{"click":function($event){return _vm.removeFilter(index)}}},on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("fal fa-times-circle")])],1)]}}],null,true)},[_c('span',[_vm._v("Remove this filter field")])])]},proxy:true}],null,true),model:{value:(_vm.fieldFilters[index].value),callback:function ($$v) {_vm.$set(_vm.fieldFilters[index], "value", $$v)},expression:"fieldFilters[index].value"}})],1)}),_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.formFields,"label":"Form fields to include in table","multiple":"","chips":"","small-chips":"","deletable-chips":"","outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticStyle:{"top":"-7px"},attrs:{"icon":"","color":"success"},on:{"click":_vm.exportSubmissions}},on),[_c('v-icon',[_vm._v("fal fa-file-excel")])],1)]}}])},[_c('span',[_vm._v("Export the filtered submissions to Excel")])])]},proxy:true}]),model:{value:(_vm.includeFields),callback:function ($$v) {_vm.includeFields=$$v},expression:"includeFields"}})],1)],2)],1),_c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"items":_vm.submissions,"server-items-length":_vm.submissionCount,"options":_vm.tableOptions,"footer-props":{ 'items-per-page-options': [5, 10, 15, 20, 30, 40, 50] }},on:{"update:options":function($event){_vm.tableOptions=$event},"dblclick:row":_vm.viewSubmissionDblClick},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-alert',{attrs:{"type":"info"}},[_vm._v("There are no submissions matching your given search criteria.")])]},proxy:true},{key:"item.submittedDate",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.stringFormatDate(item.submittedDate)))]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.viewSubmission(index, item._id)}}},on),[_c('v-icon',[_vm._v("fal fa-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("View this submission")])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }